var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Referral")]),
      _c(
        "gov-table",
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Referral method")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.referralMethod))])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }